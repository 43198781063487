import FundUnicefTemplate from '@/page-blocks/about-us/clear/fund/unicef/FundUnicefTemplate';
import ReportUnicef2019 from '@/page-blocks/about-us/clear/fund/unicef/report.unicef_2019';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicef2019: React.FC<PageProps> = (props) => (
  <FundUnicefTemplate year={2019} {...props}>
    <ReportUnicef2019 />
  </FundUnicefTemplate>
);

export default FundUnicef2019;
