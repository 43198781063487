import { FoundContent } from '@/components/Css/FoundSection';
import {
  Render,
  Article,
  ChartContainer,
  TableContainer,
  NoticeBox,
} from '@/components/Css/ReportKorea';
import DonutChartComponent from '@/components/DonutChart';
import DonutChartMobileComponent from '@/components/DonutChartMobile';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import React from 'react';

const ReportUnicef2019: React.FC = () => (
  <Render>
    <FoundContent>
      <Section>
        <SectionHeader>
          <h2>
            <Tit size="s1">유니세프 예산 구성비</Tit>
          </h2>
        </SectionHeader>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2019 수입
            </Tit>
          </h3>
          <div className="m-hide">
            <ChartContainer>
              <DonutChartComponent
                centerText="<span style='font-size: 20px;'>총 수입</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>64억 1,200만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `65%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 60 },
                      { name: `민간 및 비정부 기구 지원금`, y: 23 },
                      { name: `기구간 조정`, y: 14 },
                      { name: `기타(이자수익 등)`, y: 3 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <div className="pc-hide">
            <ChartContainer>
              <DonutChartMobileComponent
                titleText="<span style='font-size: 16px;color:#1cabe2;'>총 수입</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>64억 1,200만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                series={[
                  {
                    name: ``,
                    colorByPoint: true,
                    innerSize: `50%`,
                    type: `pie`,
                    data: [
                      { name: `각국 정부 지원금`, y: 60 },
                      { name: `민간 및 비정부 기구 지원금`, y: 23 },
                      { name: `기구간 조정`, y: 14 },
                      { name: `기타(이자수익 등)`, y: 3 },
                    ],
                  },
                ]}
              />
            </ChartContainer>
          </div>
          <TableContainer>
            <table className="tbl1">
              <colgroup>
                <col className="col1" />
                <col className="col2" />
                <col className="col3" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>금액(백만 달러)</th>
                  <th>비중</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>각국 정부 지원금</td>
                  <td>
                    <p className="price">3,848</p>
                  </td>
                  <td>
                    <p className="price">60%</p>
                  </td>
                </tr>
                <tr>
                  <td>민간 및 비정부 기구 지원금</td>
                  <td>
                    <p className="price">1,461</p>
                  </td>
                  <td>
                    <p className="price">23%</p>
                  </td>
                </tr>
                <tr>
                  <td>기구간 조정*</td>
                  <td>
                    <p className="price">897</p>
                  </td>
                  <td>
                    <p className="price">14%</p>
                  </td>
                </tr>
                <tr>
                  <td>기타(이자수익 등)</td>
                  <td>
                    <p className="price">206</p>
                  </td>
                  <td>
                    <p className="price">3%</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>계</strong>
                  </td>
                  <td>
                    <p className="price">6,412</p>
                  </td>
                  <td>
                    <p className="price">100%</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
          <NoticeBox>
            <ul className="blit-list">
              <li>
                <strong>기구간 조정 : </strong>유니세프를 포함한 여러 국제기구가
                관리하는 유엔 협력 사업비 중 유니세프 분담액
              </li>
            </ul>
          </NoticeBox>
        </Article>

        <Article>
          <h3 className="sky-bd">
            <Tit size="s2" color="sky">
              2019 지출
            </Tit>
          </h3>
          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">총지출 구성비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;'>총 지출</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>62억 5,900만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 88.3 },
                        { name: `행정관리비`, y: 6.3 },
                        { name: `민간 및 파트너십 모금비`, y: 3.5 },
                        { name: `기타`, y: 1.2 },
                        {
                          name: `특별비 (보안, 정보 통신 투자비 등)`,
                          y: 0.5,
                        },
                        { name: `유엔개발협력비`, y: 0.2 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div className="pc-hide">
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>총 지출</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>62억 5,900만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사업비`, y: 88.3 },
                        { name: `행정관리비`, y: 6.3 },
                        { name: `민간 및 파트너십 모금비`, y: 3.5 },
                        { name: `기타`, y: 1.2 },
                        {
                          name: `특별비 (보안, 정보 통신 투자비 등)`,
                          y: 0.5,
                        },
                        { name: `유엔개발협력비`, y: 0.2 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>사업비</td>
                    <td>
                      <p className="price">5,529</p>
                    </td>
                    <td>
                      <p className="price">88.3%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>행정관리비</td>
                    <td>
                      <p className="price">392</p>
                    </td>
                    <td>
                      <p className="price">6.3%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>민간 및 파트너십 모금비</td>
                    <td>
                      <p className="price">222</p>
                    </td>
                    <td>
                      <p className="price">3.5%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>기타*</td>
                    <td>
                      <p className="price">77</p>
                    </td>
                    <td>
                      <p className="price">1.2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>특별비 (보안, 정보 통신 투자비 등)</td>
                    <td>
                      <p className="price">29</p>
                    </td>
                    <td>
                      <p className="price">0.5%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>유엔개발협력비</td>
                    <td>
                      <p className="price">10</p>
                    </td>
                    <td>
                      <p className="price">0.2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">6,259</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
            <NoticeBox>
              <ul className="blit-list">
                <li>
                  <strong>기타 : </strong>각 국가위원회의 모금 및 아동 권리 증진
                  활동을 돕는 투자비, 구호 물품 조달비 등
                </li>
              </ul>
            </NoticeBox>
          </div>
          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">분야별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;'>분야별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>56억 5,000만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `생존`, y: 38.1 },
                        { name: `교육`, y: 21 },
                        { name: `환경`, y: 19.6 },
                        { name: `보호`, y: 12.5 },
                        { name: `공평한 기회 보장`, y: 8.8 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>분야별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>56억 5,000만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `생존`, y: 38.1 },
                        { name: `교육`, y: 21 },
                        { name: `환경`, y: 19.6 },
                        { name: `보호`, y: 12.5 },
                        { name: `공평한 기회 보장`, y: 8.8 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>생존</td>
                    <td>
                      <p className="price">2,152.7</p>
                    </td>
                    <td>
                      <p className="price">38.1%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>교육</td>
                    <td>
                      <p className="price">1,183.4</p>
                    </td>
                    <td>
                      <p className="price">21.0%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>환경</td>
                    <td>
                      <p className="price">1,108.9</p>
                    </td>
                    <td>
                      <p className="price">19.6%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>보호</td>
                    <td>
                      <p className="price">708.0</p>
                    </td>
                    <td>
                      <p className="price">12.5%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>공평한 기회 보장</td>
                    <td>
                      <p className="price">497.1</p>
                    </td>
                    <td>
                      <p className="price">8.8%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">5,650.0</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </div>

          <div className="tbl-wrap">
            <div className="m-hide">
              <ChartContainer>
                <Tit size="s3-1">지역별 직접 사업비</Tit>
                <DonutChartComponent
                  centerText="<span style='font-size: 20px;'>지역별 직접 사업비</span><br><b style='font-size: 36px;letter-spacing: -1.6px;color:#3162af'>56억 5,000만</b><b style='font-size:20px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `65%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 42.4 },
                        { name: `중동/북아프리카`, y: 27.4 },
                        { name: `아시아`, y: 17.1 },
                        { name: `유럽/중앙아시아`, y: 5.2 },
                        { name: `라틴아메리카/카리브해`, y: 4 },
                        { name: `지역간 조정`, y: 3.9 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <div
              className="pc-hide"
              css={`
                margin-top: 96px;
              `}
            >
              <ChartContainer>
                <DonutChartMobileComponent
                  titleText="<span style='font-size: 16px;color:#1cabe2;'>지역별 직접 사업비</span><br><b style='font-size: 26px;letter-spacing: -1.6px;color:#1cabe2'>56억 5,000만</b><b style='font-size:16px;color:#828385'> 달러</b>"
                  series={[
                    {
                      name: ``,
                      colorByPoint: true,
                      innerSize: `50%`,
                      type: `pie`,
                      data: [
                        { name: `사하라 이남 아프리카`, y: 42.4 },
                        { name: `중동/북아프리카`, y: 27.4 },
                        { name: `아시아`, y: 17.1 },
                        { name: `유럽/중앙아시아`, y: 5.2 },
                        { name: `라틴아메리카/카리브해`, y: 4 },
                        { name: `지역간 조정`, y: 3.9 },
                      ],
                    },
                  ]}
                />
              </ChartContainer>
            </div>
            <TableContainer>
              <table className="tbl1">
                <colgroup>
                  <col className="col1" />
                  <col className="col2" />
                  <col className="col3" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>금액(백만 달러)</th>
                    <th>비중</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>사하라 이남 아프리카</td>
                    <td>
                      <p className="price">2,396.5</p>
                    </td>
                    <td>
                      <p className="price">42.4%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>중동/북아프리카</td>
                    <td>
                      <p className="price">1,550.9</p>
                    </td>
                    <td>
                      <p className="price">27.4%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>아시아</td>
                    <td>
                      <p className="price">966.4</p>
                    </td>
                    <td>
                      <p className="price">17.1%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>유럽/중앙아시아</td>
                    <td>
                      <p className="price">291.2</p>
                    </td>
                    <td>
                      <p className="price">5.2%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>라틴아메리카/카리브해</td>
                    <td>
                      <p className="price">224.6</p>
                    </td>
                    <td>
                      <p className="price">4.0%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>지역간 조정</td>
                    <td>
                      <p className="price">220.6</p>
                    </td>
                    <td>
                      <p className="price">3.9%</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>계</strong>
                    </td>
                    <td>
                      <p className="price">5,650.0</p>
                    </td>
                    <td>
                      <p className="price">100%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>

            <NoticeBox>
              <ul className="blit-list">
                <li>
                  유니세프 지출예산은 당해년도 회기 1~2년 전에 집행이사회로부터
                  승인을 받습니다. <br />
                  이로 인해 당해년도의 수입과 지출의 차이가 발생하며, 당해년도
                  차액은 다음 해의 프로그램 예산에 반영됩니다.
                </li>
                <li>
                  각 구분 금액의 합은 반올림에 의해 총 액과 다를 수 있습니다.
                </li>
                <li>
                  출처 :&nbsp;
                  <a
                    href="https://www.unicef.org/reports/annual-report-2019"
                    className="link-blank"
                    target="_balnk"
                  >
                    &nbsp;UNICEF Annual Report 2019
                  </a>
                </li>
              </ul>
            </NoticeBox>
          </div>
        </Article>
      </Section>
    </FoundContent>
  </Render>
);
export default ReportUnicef2019;
